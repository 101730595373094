<template>
  <div class="row">
    <div class="col-md-2">
      <div class="card">
        <div class="card-body pb-0 pt-2">
          <h4 class="card-title">Total Views</h4>
          <h3>{{ count.total_views }}</h3>
        </div>
        <div class="card-body border-top py-2">
          <div class="">
            <template v-if="statsData[0].subvalue">
              <span
                class="badge font-size-11"
                :class="
                  statsData[0].subvalue > 0
                    ? 'badge-soft-success'
                    : 'badge-soft-danger'
                "
              >
                <i
                  class="mdi"
                  :class="
                    statsData[0].subvalue > 0 ? 'mdi-menu-up' : 'mdi-menu-down'
                  "
                ></i>
                {{ statsData[0].subvalue }} %
              </span>
              <span class="text-muted ml-2">{{ statsData[0].subtext }}</span>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-2">
      <div class="card">
        <div class="card-body pb-0 pt-2">
          <h4 class="card-title">Total Viewers</h4>
          <h3>{{ count.total_viewers }}</h3>
        </div>
        <div class="card-body border-top py-2">
          <div class="">
            <template v-if="statsData[1].subvalue">
              <span
                class="badge font-size-11"
                :class="
                  statsData[1].subvalue > 0
                    ? 'badge-soft-success'
                    : 'badge-soft-danger'
                "
              >
                <i
                  class="mdi"
                  :class="
                    statsData[1].subvalue > 0 ? 'mdi-menu-up' : 'mdi-menu-down'
                  "
                ></i>
                {{ statsData[1].subvalue }} %
              </span>
              <span class="text-muted ml-2">{{ statsData[1].subtext }}</span>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-2">
      <div class="card">
        <div class="card-body pb-0 pt-2">
          <h4 class="card-title">Avg. Page Visits</h4>
          <h3>{{ pages_per_visit.toFixed(2) }}</h3>
        </div>
        <div class="card-body border-top py-2">
          <div class="">
            <template v-if="statsData[2].subvalue">
              <span
                class="badge font-size-11"
                :class="
                  statsData[2].subvalue > 0
                    ? 'badge-soft-success'
                    : 'badge-soft-danger'
                "
              >
                <i
                  class="mdi"
                  :class="
                    statsData[2].subvalue > 0 ? 'mdi-menu-up' : 'mdi-menu-down'
                  "
                ></i>
                {{ statsData[2].subvalue }} %
              </span>
              <span class="text-muted ml-2">{{ statsData[2].subtext }}</span>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-2">
      <div class="card">
        <div class="card-body pb-0 pt-2">
          <h4 class="card-title">Avg. Visit Duration</h4>
          <h3>{{ article_time_spent }}</h3>
        </div>
      </div>
    </div>
    <div class="col-md-2">
      <div class="card">
        <div class="card-body pb-0 pt-2">
          <h4 class="card-title">Total Articles Published</h4>
          <h3>{{ count.articles_published }}</h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import appConfig from "@/app.config";
import filterMixins from "../../../mixins/filterData";

export default {
  props: ["filters"],
  mixins: [filterMixins],
  data() {
    return {
      count: 0,
      pages_per_visit: 0,
      previous_page_per_visit: 0,
      article_time_spent: 0,
      statsData: [
        {
          subvalue: 0,
          subtext: "From Previous Period",
        },
        {
          subvalue: 0,
          subtext: "From Previous Period",
        },
        {
          subvalue: 0,
          subtext: "From Previous Period",
        },
      ],
    };
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        // console.log('comming..');
        // console.log(this.filters);
        this.getCount();
      },
    },
  },
  methods: {
    async getCount() {
      const searchfiltersParams = this.getCleanFilteredData(this.filters);
      try {
        const response = await this.$http.get(
          appConfig.api_base_url + "/articles/count",
          {
            params: searchfiltersParams,
          }
        );
        this.count = response.data;

        this.statsData[0].subvalue =
          this.count.previous_period_views !== 0
            ? (
                ((this.count.total_views - this.count.previous_period_views) /
                  this.count.previous_period_views) *
                100
              ).toFixed(2)
            : "0";
        this.statsData[0].subvalue = !isNaN(this.statsData[0].subvalue)
          ? this.statsData[0].subvalue
          : "0";

        this.statsData[1].subvalue =
          this.count.previous_period_viewers !== 0
            ? (
                ((this.count.total_viewers -
                  this.count.previous_period_viewers) /
                  this.count.previous_period_viewers) *
                100
              ).toFixed(2)
            : "0";
        this.statsData[1].subvalue = !isNaN(this.statsData[1].subvalue)
          ? this.statsData[1].subvalue
          : "0";

        this.article_time_spent = this.toHHMMSS(Math.round(this.count.article_time_spent));
        this.pages_per_visit = (this.count.pages_per_visit.total_articles_read /this.count.pages_per_visit.members_count);
        this.previous_page_per_visit = (this.count.previous_page_per_visit.total_articles_read /this.count.previous_page_per_visit.members_count);
        this.statsData[2].subvalue =
          this.previous_page_per_visit !== 0
            ? (((this.pages_per_visit - this.previous_page_per_visit) / this.previous_page_per_visit) * 100).toFixed(2): "0";
          this.statsData[2].subvalue = !isNaN(this.statsData[2].subvalue)? this.statsData[2].subvalue: "0";
      } catch (err) {
        console.log(err);
      }
    },
    toHHMMSS(secs) {
        var sec_num = parseInt(secs, 10)
        var hours   = Math.floor(sec_num / 3600)
        var minutes = Math.floor(sec_num / 60) % 60
        var seconds = sec_num % 60
        return [hours,minutes,seconds]
            .map(v => v < 10 ? "0" + v : v)
            .join(":")
    }
  },
  mounted() {
    this.getCount();
  },
};
</script>
